class KeyboardShortcuts {
  constructor () {
    this.shortcuts();
  }

  shortcuts () {
    let currentKeys = [];
    let timeoutId;
    const formElements = ['INPUT', 'TEXTAREA', 'SELECT', 'BUTTON', 'DATALIST', 'KEYGEN', 'LABEL', 'LEGEND', 'METER', 'OUTPUT', 'PROGRESS'];

    document.addEventListener('keydown', (event) => {
      if (formElements.includes(event.target.tagName))
        return; // ignore keydown events when the user is typing in a form element

      currentKeys.push(event.key);
      resetTimer();

      // Global navigation
      if (event.key === 'h') go('#kb-home-link');
      if (event.key === '/') {
        document.querySelector('.js-awesomplete-input').focus();
        event.preventDefault();
      }
      if (event.key === 'f' && document.querySelector('.js-navbar-filter-btn')) document.querySelector('.js-navbar-filter-btn').click();
      if (event.ctrlKey && event.key === 'n') go('#kb-new-domain-link');

      // Domain related navigation
      if (event.key === 'e') go('#kb-dns-editor-link');
      if (event.key === 's') go('#kb-ssl-link');
      if (event.key === 'o') go('#kb-one-click-service-link');

      // Current account navigation
      if (currentKeys.join('') === 'gd') go('#kb-domains-link');
      if (currentKeys.join('') === 'gc') go('#kb-contacts-link');
      if (currentKeys.join('') === 'gt') go('#kb-templates-link');
      if (currentKeys.join('') === 'ga') go('#kb-account-link');

      // Templates
      if (currentKeys.join('') === 'tn') go('#kb-templates-new-link');

      // Contacts
      if (currentKeys.join('') === 'cn') go('#kb-contacts-new-link');

      // Miscellaneous
      if (event.key === '?') {
        const modal = new window.Modal(document.querySelector('#keyboardShortcutsModal'));
        if (!document.querySelector('#keyboardShortcutsModal').classList.contains('show')) modal.show();
      }

      function go (selector) {
        const el = document.querySelector(selector);
        if (el) {
          const path = el.dataset.path;
          if (path) window.location.assign(path);
          else alert("Cannot navigate to location because you don't have an account selected");
        } else
          alert("Cannot navigate to location because you don't have an account selected");
      }

      function resetTimer () {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
          currentKeys = [];
        }, 1000);
      }
    });
  }

  static ready () {
    if (document.getElementById('keyboardShortcutsModal'))
      new KeyboardShortcuts();
  }
}

export default KeyboardShortcuts;
